<template>
  <div />
</template>

<script>
export default {
  name: "Charities",
  data() {
    return {};
  },
};
</script>


<style scoped>
</style>