<template>
  <div
    id="admin-subcontainer"
  >
    <!-- class="admin-container" -->
    <router-view />
  </div>
</template>

<script>

export default {
}
</script>

<style scoped lang='scss'>
</style>
