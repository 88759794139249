<template>
  <div class="container">
    <h1>Dashboard</h1>
    <ToggleVoting />
  </div>
</template>

<script>
import ToggleVoting from '@/components/OpenVoting.vue'

export default {
  components : {
    ToggleVoting
  }

}
</script>

<style scoped lang='scss'></style>
