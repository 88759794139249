<template>
  <div class="cookie_policy">
    <h1>
      Our cookie policy
    </h1>
    <h2 id="introduction">
      1 - Introduction
    </h2>
    <ul>
      <li>
        This website uses Cookies
      </li>
      <li>
        With the exception of <a href="#strictlyneccesary"> strictly neccesary cookies </a>, we will not place cookies on your device without explicit consent to do so.
      </li>
      <li>
        Most parts of this website will work without the use of cookies, however we cannot guarantee full website functionality without the usage of some cookies.
      </li>
    </ul>

    <h2 id="information">
      2 - Information
    </h2>
    <ul>
      <li>
        Cookies are small blocks of data that we, or a trusted <a href="#thirdparty"> third party</a> , place on your device. These can be used to provide functionality, such as saving user settings, or for the collection of analytical data.
      </li>
      <li>
        We use cookies in multiple different ways. You are able to choose which types of cookies to allow and which to not. Consent can be revoked at any time.
      </li>
      <li>
        How we use each type of cookies is outlined below
      </li>
      <li>
        Cookies from third parties are covered seperately
      </li>
    </ul>
    <h2 id="strictlyneccesary">
      3 - Strictly Neccesary Cookies
    </h2>
    <ul>
      <li>
        Strictly neccesary cookies are cookies neccesary for the website to function
      </li>
      <li>
        These cookies are enabled by default, and cannot be disabled
      </li>
      <li>
        Strictly neccesary cookies are not used to collect user data
      </li>
      <li>
        We use the following strictly neccesary cookies:
      </li>
      <cookie-table :cookies="strictlyNecessaryCookies" />
    </ul>

    <h2 id="functional">
      4 - Functional Cookies
    </h2>
    <ul>
      <li> Functional cookies are cookies used to provide functionality to the website </li>
      <li> These cookies are not essential, and can therefore be disabled by the user, however by disabling these cookies, website functionality may be lost </li>
      <li>
        Functional cookies are not used to collect user data
      </li>
      <li>
        We use the following functional cookies:
      </li>

      <cookie-table :cookies="functionalCookies" />
    </ul>
    <h2 id="analytical">
      5 - Analytical Cookies
    </h2>
    <ul>
      <li> Analytical cookies are cookies used to collect data on how a site is used </li>
      <li> These cookies are not functional, and therefore disabling them will likely not impact functionality</li>
      <li>
        Analytical cookies are not used to collect marketing data
      </li>
      <li>
        We use the following analytical cookies:
      </li>

      <cookie-table :cookies="analyticalCookies" />
    </ul>
    <h2 id="thirdparty">
      6 - Third Party Cookies
    </h2>
    <ul>
      <li> Third party cookies are cookies set by a website other than this website </li>
      <li> These occur when we access other websites through this website, such as through embeds</li>
      <li>
        Third party cookies may be of any of the above types, or other
      </li>
      <li>
        Disabling third party cookies may impact functionality
      </li>
      <li>
        The following sites may be able to place 3rd party cookies on your device when you access this website:
      </li>

      <third-party-cookie-table :cookies="thirdPartyCookies" />
    </ul>

    <div class="buttons">
      <button
        class="button is-info is-large"
        @click="revokePreference()"
      >
        Revoke cookie preference
      </button>
    </div>
  </div>
</template>

<script>
import CookieTable from './CookieTable.vue'
import ThirdPartyCookieTable from './ThirdPartyCookieTable.vue'
export default {
  components: {
    'cookie-table': CookieTable,
    'third-party-cookie-table': ThirdPartyCookieTable
  },
  computed: {
    strictlyNecessaryCookies() {
      return this.$svsSettings.strictlyNecessaryCookies
    },
    functionalCookies () {
      return this.$svsSettings.functionalCookies
    },
    analyticalCookies () {
      return this.$svsSettings.analyticalCookies
    },
    thirdPartyCookies () {
      return this.$svsSettings.thirdPartyCookies
    }
  },
  methods: {
    revokePreference () {
      this.$svsSettings.deleteCookiePereference()
    }
  }
}
</script>

<style scoped lang='scss'>

  h1 {
    margin-top: 0;
  }

  table {
    width: 100%;
    color: inherit;
    border-collapse: collapse;
  }

  .cookie_policy {
    width: 80%;
    margin: auto;
  }

  td {
    padding: 5px;
  }

  th {
    color: inherit;
  }

</style>
